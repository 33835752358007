import axios from 'axios'
import { HTTP_STATUS } from '../constants/common'
import { authActions } from '../redux/slices/authSlice'
import { store } from '../redux/store'
import Cookies from 'utils/cookies'
import { refreshToken } from './auth.service'

const TIMEOUT = 30000

function getAuthorizationHeader() {
  const token = Cookies.getToken()
  return token ? `Bearer ${token}` : undefined
}

const handleApiCallError = (error) => {
  const { status } = error.response || {}

  if ([HTTP_STATUS.UNAUTHORIZED, HTTP_STATUS.FORBIDDEN].includes(status)) {
    store.dispatch(authActions.logout())
  }
}

const handleAuthError = (error) => {
  if (error.response.status === HTTP_STATUS.UNAUTHORIZED) {
    store.dispatch(authActions.logout())
  }
}

export const funcFetch = async (axiosInstance, endPoint, method, data, headers, options) => {
  const max_time = options?.retry_time || 3
  let counter = 0

  axiosInstance.interceptors.response.use(
    async (response) => {
      const { status, data } = response

      if (status < HTTP_STATUS.MULTIPLE_CHOICES && data) {
        return data.data
      } else {
        throw new Error(data.message)
      }
    },
    async (error) => {
      const { config } = error

      if (
        config?.url.indexOf('logout') === -1 &&
        counter < max_time &&
        error.response.status === HTTP_STATUS.UNAUTHORIZED
      ) {
        counter++

        try {
          const newToken = await refreshToken()

          if (newToken) {
            Cookies.setToken(newToken.access_token)
            config.headers.Authorization = `Bearer ${newToken.access_token}`

            return axiosInstance(config)
          } else {
            handleAuthError(error)
          }
        } catch (error) {
          handleApiCallError(error)
        }
      }

      return Promise.reject(error)
    }
  )

  const dataValue = ['get', 'delete'].includes(method.toLowerCase()) ? { data } : data;

  return axiosInstance[method](endPoint, dataValue, headers)
}

const fetchApi = async (
  endPoint = '',
  data = null,
  method = 'get',
  headers = {},
  url = process.env.REACT_APP_API_URL
) => {
  const axiosInstance = axios.create({
    baseURL: url,
    headers: {
      'Accept-Language': 'vn',
      ...headers,
      Authorization: getAuthorizationHeader(),
    },
    timeout: TIMEOUT,
  })

  return funcFetch(axiosInstance, endPoint, method, data, headers, { retry_time: 3 })
}

export default fetchApi
