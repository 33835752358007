import { Button, Modal } from '@mui/material'
import { makeStyles } from "@material-ui/core/styles";
import { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
  border: 'none',
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'transparent',
    border: '0px solid #000',
    boxShadow: theme.shadows[0],
    outline: 'none',
    position: 'relative',
    textAlign:'center',
    '& .btn': {
      position: 'absolute',
      top: '8px',
      right: '15%',
      // backgroundColor: 'white'
      color: 'white',

    }
  },
}));

export const Popup = ({open, setOpen}) => {
  const classes = useStyles();
  if (!open) {
    return
  }
  return (
    <Modal
        open={open}
        onClose={() => setOpen(!open)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modal}
      >
      <div className={classes.paper}>
        <img
       src="https://pm2ec.s3.ap-southeast-1.amazonaws.com/tickets/17179839183411580.jpg"
        alt="Centered Image"
        style={{
           width: '70%',
          aspectRatio: 2,
          margin: '0 auto'
         }}
      />
          <Button className='btn' onClick={() => setOpen(!open)}><CloseIcon fontSize='large'/></Button>
        </div>
      </Modal>
  )
}
