/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'

import { Box, Button, Paper, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import IconEdit from 'icons/IconEdit'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
// Icons
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'

// Components

// Services
import * as portalService from 'services/portal.service'

import Header from 'components/Header'
import MainContent from 'components/MainContent'
import { useParams } from 'react-router-dom'

import { concat, isEmpty, merge, omitBy } from 'lodash'
import { createMessage } from 'utils/common'
import { MESSAGE_TYPES, MODULE, listModule } from 'constants/common'
import { useDispatch } from 'react-redux'

const convertService = (inputPermissions) => {
  const result = {}

  for (const key in inputPermissions) {
    if (inputPermissions[key]) {
      result[key] = ['Read']
    }
  }

  return result
}

function formatPermissions(permissions) {
  const formatted = {}

  Object.keys(permissions).forEach((key) => {
    const value = permissions[key]

    if (Object.keys(value).length === 0) {
      return
    }

    formatted[key] = Object.keys(value).filter((k) => value[k])
  })

  return formatted
}

export default function EditRole() {
  const [isLoading, setIsLoading] = React.useState(true)
  const [isLoadingButtonSubmit, setIsLoadingButtonSubmit] = React.useState(false)
  const [value, setvalue] = React.useState('')
  const [permission, setPermission] = React.useState({})
  const [data, setData] = React.useState({})
  const [editMode, setEditMode] = React.useState(false)
  const [selectedServices, setSelectedServices] = React.useState({})
  const [checkedPermissions, setCheckedPermissions] = React.useState({})
  const dispatch = useDispatch()
  const { id } = useParams()

  const fetchData = async (id) => {
    setIsLoading(true)

    try {
      const data = await portalService.getRoleDetail(id)

      const module = await portalService.getModuleAndService()
      setData(module)
      setvalue(data.name)
      setPermission(data.permissions)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }
  React.useEffect(() => {
    if (!id) return
    fetchData(id)
  }, [id])

  const handleUpdate = async () => {
    if (value.length < 5) {
      dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: 'Bạn phải nhập ít nhất 5 ký tự' }))
      return
    }

    setIsLoadingButtonSubmit(true)
    let service = convertService(selectedServices)
    let permission = formatPermissions(checkedPermissions)

    
    let mergeData = merge(service, permission)

    const data = {
      name: value,
      permissions: mergeData,
    }

    // Check if mergeData is an empty object
    if (Object.keys(mergeData).length === 0) {
      // If empty, remove the 'permissions' property from the data object
      delete data.permissions
    }

    try {
      await portalService.updateRole(id, data)
      dispatch(createMessage({ type: MESSAGE_TYPES.SUCCESS, content: 'Cập nhật quyền thành công!' }))
      fetchData(id)
      setIsLoadingButtonSubmit(false)
    } catch (error) {
      console.log(error)
      dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: 'Cập nhật quyền không thành công!' }))
      setIsLoadingButtonSubmit(false)
    }
  }

  return (
    <MainContent>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Header title="Danh sách phân quyền" />
        <Paper
          sx={{
            width: '100%',
            mt: 3,
            filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.04))',
            borderRadius: '6px',
            boxShadow: 'none',
          }}
        >
          <Box sx={{ padding: '16px', display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', gap: '8px' }}>
              <AccountCircleOutlinedIcon sx={{ width: '20px', height: '20px' }} />
              <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>Vai trò:</Typography>
            </Box>

            <Box>
              <TextField
                value={value}
                onChange={(e) => {
                  setvalue(e.target.value)
                }}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setEditMode(!editMode)}>
                        <IconEdit />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                disabled={!editMode}
              />
            </Box>
          </Box>
        </Paper>

        <Paper
          sx={{
            width: '100%',
            mt: 2,
            filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.04))',
            borderRadius: '6px',
            boxShadow: 'none',
            display: 'flex',
            justifyContent: 'end',
            padding: '20px',
          }}
        >
          <Box>
            <Button variant="contained" disabled={isLoadingButtonSubmit} onClick={handleUpdate} className="header">
              Lưu
            </Button>
          </Box>
        </Paper>

        <Paper
          sx={{
            width: '100%',

            filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.04))',
            borderRadius: '6px',
            boxShadow: 'none',
            display: 'flex',
            padding: '32px',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px', width: '50%' }}>
            <Typography sx={{ fontSize: '16px', fontWeight: '600!important' }}>MODULES</Typography>
            {!isEmpty(data) && (
              <ModulePermissions
                modulePermission={concat(data?.modules,data?.module_specific) }
                permissions={permission}
                setCheckedPermissions={setCheckedPermissions}
                checkedPermissions={checkedPermissions}
              />
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px', width: '50%' }}>
            <Typography sx={{ fontSize: '16px', fontWeight: '600!important' }}>MINI APP</Typography>
            {!isEmpty(data) && (
              <ModuleService
                moduleService={data?.module_services}
                permissions={permission}
                setSelectedServices={setSelectedServices}
                selectedServices={selectedServices}
              />
            )}
          </Box>
        </Paper>
      </Box>
    </MainContent>
  )
}

const ModulePermissions = ({ modulePermission, permissions, checkedPermissions, setCheckedPermissions }) => {
  const allPermissions = ['Read', 'Create', 'Delete', 'Update']
  const [initialized, setInitialized] = React.useState(false)

  const handleCheckboxChange = (moduleKey, permission) => {
    setCheckedPermissions((prevCheckedPermissions) => {
      const existingPermissions = prevCheckedPermissions[moduleKey] || {}
      const isChecked = existingPermissions[permission]

      return {
        ...prevCheckedPermissions,
        [moduleKey]: {
          ...existingPermissions,
          [permission]: !isChecked,
        },
      }
    })
  }

  React.useEffect(() => {
    const defaultCheckedPermissions = {}

    modulePermission.forEach((module) => {
      const moduleKey = module?.key
      const modulePermissions = permissions[moduleKey] || []

      const defaultPermissions = {}
      allPermissions.forEach((permission) => {
        if (modulePermissions.includes(permission)) {
          defaultPermissions[permission] = true
        }
      })

      defaultCheckedPermissions[moduleKey] = defaultPermissions
    })

    setCheckedPermissions(defaultCheckedPermissions)
    setInitialized(true)
  }, [])
  const renderCheckboxes = () => {
    return modulePermission.map((module) => {
      console.log(module?.value);
      if (MODULE.includes(module?.value)) {
        
        const moduleKey = module?.key
        return (
          <div key={moduleKey}>
            <Typography sx={{ fontSize: '14px', fontWeight: '600!important' }}>{listModule[module?.value]}</Typography>
            <FormGroup sx={{ paddingLeft: '12px' }}>
              {allPermissions.map((permission, index) => (
                <FormControlLabel
                  key={permission + index}
                  control={
                    <Checkbox
                      checked={initialized && checkedPermissions[moduleKey]?.[permission]}
                      onChange={() => handleCheckboxChange(moduleKey, permission)}
                    />
                  }
                  label={permission}
                />
              ))}
            </FormGroup>
          </div>
        )
      }
    })
  }

  return <Box>{renderCheckboxes()}</Box>
}

const ModuleService = ({ moduleService, permissions, selectedServices, setSelectedServices }) => {
  const [initialized, setInitialized] = React.useState(false)

  React.useEffect(() => {
    const initialSelectedServices = {}

    moduleService.forEach((module) => {
      const moduleKey = module?.key
      initialSelectedServices[moduleKey] = permissions[moduleKey]?.includes('Read') || false
    })

    setSelectedServices(initialSelectedServices)
    setInitialized(true)
  }, [])

  const handleCheckboxChange = (moduleKey) => {
    setSelectedServices((prevSelectedServices) => {
      return {
        ...prevSelectedServices,
        [moduleKey]: !prevSelectedServices[moduleKey],
      }
    })
  }

  const renderCheckboxes = () => {
    return moduleService.map((module) => {
      const moduleKey = module?.key
      return (
        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <Checkbox
            checked={(initialized && selectedServices[moduleKey]) || module?.is_public}
            disabled={module?.is_public}
            onChange={() => handleCheckboxChange(moduleKey)}
          />
          {module?.value}
        </Box>
      )
    })
  }

  return (
    <Box>
      <FormGroup>{renderCheckboxes()}</FormGroup>
    </Box>
  )
}
