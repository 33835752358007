import { Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography, styled, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import { memo } from 'react'

import CloseIcon from '@mui/icons-material/Close'
const StyledDialog = styled('div')(() => {
  return {
    '& p, & h5': {
      fontFamily: 'Be Vietnam Pro, sans-serif',
      fontStyle: 'normal',
      color: '#333333',
    },
    '& .title': {
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '24px',
      textAlign: 'center',
    },
    '& .avatar': {
      textAlign: 'center',
      backgroundImage: 'url("/bgAvatar.svg")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      padding: '14px 0',
      borderRadius: '8px',
      backgroundColor: '#DB1B33',
    },
    '& .form-control': {
      marginBottom: 0,
    },
    '& .title-input': {
      marginBottom: '4px',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#525252',
    },
  }
})

FormBox.propTypes = {
  content: PropTypes.element,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  showIcon: PropTypes.bool,
  open: PropTypes.bool,
  key: PropTypes.string,
}

FormBox.defaultProps = {
  title: 'Thông tin',
  onClose: () => { },
  onConfirm: () => { },
  showIcon: true,
}

function FormBox({ title, onClose, onConfirm, showIcon, open, children, key, content, alignItems, maxWidth }) {
  const handleClose = (e, r) => {
    if (r === 'backdropClick') {
      return
    } else {
      onClose()
    }
  }

  const matchesMobile = useMediaQuery(`(max-width:600px)`)
  return (
    <Dialog key={key} fullWidth maxWidth="md" open={open} scroll="paper" onClose={handleClose} sx={{
      '& .MuiDialog-container': {
        height: `calc(100vh - -28px)`,
        alignItems: alignItems || 'flex-start',
        '& .MuiDialog-paper': {
          marginTop: '24px',
          maxWidth: maxWidth || 'auto'
        }
      },
      '& div, & p': {
        fontFamily: 'Be Vietnam Pro, sans-serif',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#333333',
      }
    }}>
      <StyledDialog>
        <DialogTitle>
          <Typography className="title">{title}</Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ p: `${matchesMobile ? '12px' : '16px 0 12px 0'}` }}>{content}</DialogContent>
      </StyledDialog>
    </Dialog>
  )
}

export default memo(FormBox)
