import { Button, Grid, Typography, useMediaQuery } from '@mui/material'

import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
const StyledHeader = styled('div')(() => {
  return {
    '& .title': {
      fontFamily: 'Be Vietnam Pro, sans-serif',
      fontStyle: 'normal',
      fontWeight: '700',
      color: '#525252',
    },
    '& p': {
      whiteSpace: 'nowrap',
      fontFamily: 'Be Vietnam Pro, sans-serif',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      color: '#333333',
      // marginLeft: '20px'
    },
    '& .MuiGrid-root': {
      marginBottom: 0,
    },
  }
})
function Header(props) {

  const navigate = useNavigate()
  const matches = useMediaQuery(`(max-width:1600px)`)
  const matchesMobile = useMediaQuery(`(max-width:600px)`)
  return (
    <StyledHeader
      sx={(theme) => ({
        marginBottom: `${matches ? '10px' : '24px'}`,
        [theme.breakpoints.down('sm')]: {
          mb: '10px'
        },
        '& .title, & h4': {
          fontSize: '22px',
          lineHeight: '28px',
        },
      })}
    >
      <Grid sx={{ mb: 4 }} container justifyContent="space-between">
        <Grid item md={6} xs={props.btn && 6} sx={{ display: 'flex' }} alignItems="center">
          {!matchesMobile && <Button size='small'
            onClick={() => {
              navigate(-1)
            }}
            sx={{
              borderRadius: '6px',
              border: '1px solid #EEEEEE',
              backgroundColor: '#FFFFFF',
              padding: '10.5px 12px',
              mr: '24px',
              width: '43px',
              height: '40px',
              minWidth: '0',
              '&:hover': {

                backgroundColor: '#FFFFFF',
              }
            }}>
            <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M5.40286 9.67399C5.50911 9.78024 5.6437 9.82982 5.77828 9.82982C5.91286 9.82982 6.05453 9.78024 6.1537 9.67399C6.35911 9.46857 6.35911 9.12857 6.1537 8.92315L2.7607 5.53015H13.5204C13.8108 5.53015 14.0516 5.28932 14.0516 4.9989C14.0516 4.70848 13.8108 4.46765 13.5204 4.46765H2.76086L6.1537 1.07482C6.35911 0.869401 6.35911 0.529401 6.1537 0.323984C5.94828 0.118568 5.60828 0.118568 5.40286 0.323984L1.10328 4.62357C0.897865 4.82899 0.897865 5.16898 1.10328 5.3744L5.40286 9.67399Z" fill="#333333" />
            </svg>
          </Button>}
          <Typography className="title" variant="h4">
            {props.title}
          </Typography>
        </Grid>
        <Grid item md={6} xs={props.btn && 6} sx={{ display: 'flex' }} justifyContent="flex-end">
          {props.btn}
        </Grid>
      </Grid>
    </StyledHeader>
  )
}

export default Header
