import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { useMediaQuery } from '@mui/material'

function MiniApp(props) {
  const matches = useMediaQuery(`(max-width:1600px)`)
  const isMobile = useMediaQuery(`(max-width:768px)`)
  return (
    <Box
      sx={
        matches
          ? {
              //height: '252px',
              minHeight: 150,
              cursor: 'pointer',
              backgroundColor: 'rgba(255, 255, 255, 0.4)',
              boxSizing: 'border-box',
              padding: '18px 12px',
              border: '1px solid rgba(0, 0, 0, 0.06)',
              borderRadius: '16px',
              '&:hover': {
                outline: '2px solid #C92127',
                border: '1px solid transparent',
                backgroundImage: 'url("/bgMiniApp.svg")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
              },
            }
          : {
              // minWidth: `${matches ? 0 : '352px'}`,
              // minHeight: `${matches ? 0 : '286px'}`,
              cursor: 'pointer',
              backgroundColor: 'rgba(255, 255, 255, 0.4)',
              minHeight: 190,
              boxSizing: 'border-box',
              padding: '16px',
              border: '1px solid rgba(0, 0, 0, 0.06)',
              borderRadius: '16px',
              '&:hover': {
                outline: '2px solid #C92127',
                border: '1px solid transparent',
                backgroundImage: 'url("/bgMiniApp.svg")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
              },
            }
      }
    >
      <Link color="inherit" sx={{ textDecoration: 'none' }} href={props.href} title={props.description}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 0 }}>
          <Box>
            {matches ? (
              <img width={'64px'} height={'64px'} src={props?.logo} alt="" style={{ borderRadius: '50%' }} />
            ) : (
              <img width={'64px'} height={'64px'} src={props?.logo} alt="" style={{ borderRadius: '50%' }} />
            )}
          </Box>

          <Typography
            variant="h3"
            sx={
              matches
                ? {
                    paddingTop: '8px',
                    paddingBottom: '4px',
                    fontWeight: '700',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#333333',
                    textAlign: "center"
                  }
                : {
                    paddingTop: '16px',
                    paddingBottom: '4px',
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#333333',
                    textAlign: "center"
                  }
            }
          >
            {props.appName}
          </Typography>
          <Typography
            sx={
                {
                    textAlign: 'center',
                    fontWeight: '400',
                    fontSize: '12px !important',
                    lineHeight: '16px',
                    color: '#737373',
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: isMobile ? 1: 2,
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical"
                  }
            }
          >
            {props.description}
          </Typography>
        </Box>
      </Link>
    </Box>
  )
}

export default MiniApp
