import { call, put, takeLatest } from 'redux-saga/effects'
import { userActions } from 'redux/slices/userSlice'
import LocalStorage from 'utils/localStorage'
import * as authService from '../../services/auth.service'
import { authActions } from '../slices/authSlice'
import { storeActions } from '../slices/storeSlice'
import Cookies from 'utils/cookies'

const login = function* ({ payload }) {
  const { meta, ...data } = payload

  try {
    const res = yield call(authService.login, data)

    if(res){
      Cookies.setToken(res.access_token)
      Cookies.setRefreshToken(res.refresh_token)
  
      yield put(authActions.loginSuccess(data))
  
      if (meta?.onSuccess) {
        meta.onSuccess(res)
      }
    }
  } catch (error) {
    console.log('error', error)

    yield put(authActions.loginError(error))
    if (meta?.onError) {
      meta.onError(error)
    }
  }
}

const logout = function* ({ payload = { onSuccess: () => {}, onError: () => {} } }) {
  try {
    yield call(authService.logout)

    Cookies.remove('token')
    Cookies.remove('refresh_token')

    yield put(storeActions.clearStore())

    if (window.location.href.indexOf('/login') === -1) window.location.href = '/login'
  } catch (error) {
    console.log('error', error)
  }
}

export default function* authSaga() {
  yield takeLatest(authActions.login.type, login)
  yield takeLatest(authActions.logout.type, logout)
}
