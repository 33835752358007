import Cookies from 'utils/cookies'
import fetchApi from './config'
import axios from 'axios'

let authUrl = process.env.REACT_APP_PORTAL_URL_API

export const login = async ({ username, password }) => {
  return fetchApi('/login', { username, password }, 'post', {}, authUrl)
}

export const logout = async () => {
  return await fetchApi('/logout', {}, 'post', {}, authUrl)
}

/**
 * Obtain new access token by refresh token
 * @returns {Promise<*>}
 */

export const refreshToken = async () => {
  const refreshToken = await Cookies.getRefreshToken()

  if (!refreshToken) return Promise.reject('Refresh token not found')

  try {
    const res = await axios.post(
      `${authUrl}/token/refresh`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + refreshToken,
        },
      }
    )

    return res.data?.data
  } catch (error) {
    return null
  }
}
