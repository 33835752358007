import { Navigate, Outlet, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import useAuth from 'hooks/useAuth'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import Cookies from 'utils/cookies'

const ProtectedPage = () => {
  const location = useLocation()
  const userInfo = useSelector((state) => state.user.user)
  const isAuthenticated = useAuth()

  const hasEnoughUserInfomation = userInfo.stores && _.isArray(userInfo.stores) && userInfo.stores.length > 0
  // super admin pass qua profile luôn(chưa làm)
  if (isAuthenticated && !hasEnoughUserInfomation) {
    if (!(location.pathname.search('/user/profile') > -1)) {
      return <Navigate to="/user/profile" />
    }
  }

  if(isAuthenticated){
    return <Outlet />
  }

  if(!window.location.href.includes("/login")){
    Cookies.set('redirect_url', window.location.href)
  }

  return <Navigate to="/login" />
}

ProtectedPage.propTypes = {
  allowedRoles: PropTypes.array,
}

export default ProtectedPage
