import { Box, styled, useMediaQuery } from '@mui/material'
import { footerToolbarHeight } from 'config'
import PropTypes from 'prop-types'

const MainContent = styled(({ className, children, footerToolbar }) => {
  const matches = useMediaQuery(`(max-width:1600px)`)
  return (
    <Box
      className={['main-content', className].join(' ')}
      sx={(theme) => ({
        padding: `${matches ? '12px 32px 52px' : '24px 32px 52px'}`,
        [theme.breakpoints.down('sm')]: {
          padding: '0 8px',            
        },
        backgroundColor: '#FAFAFA',
        minHeight: `calc(100vh - 60px)`,
      })}
    >
      <Box
        sx={(theme) => ({
          //paddingBottom: `${footerToolbarHeight}px`,

          [theme.breakpoints.down('sm')]: {
            paddingTop: '8px',
            paddingBottom: '8px',
          },
        })}
      >
        {children}
      </Box>
      {/* {footerToolbar && <Box className="footer-toolbar">{footerToolbar}</Box>} */}
    </Box>
  )
})(({ theme }) => ({
  '& .footer-toolbar': {
    display: 'inline-flex',
    background: '#fff',
    position: 'fixed',
    bottom: 0,
    right: 0,
    justifyContent: 'flex-end',
    width: '100%',
    borderTop: '1px solid #ddd',
    padding: '5px 24px',
    boxShadow: 'rgb(100 116 139 / 12%) 1px 0px 4px',
  },
}))

MainContent.propTypes = {
  footerToolbar: PropTypes.any,
}

export default MainContent
