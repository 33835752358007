import styled from '@emotion/styled'
import { Box, Grid, useMediaQuery } from '@mui/material'
import Typography from '@mui/material/Typography'
import FormBox from 'components/ui/UiDialog/FormBox'
import { TYPE_APP } from 'constants/common'
import Link from '@mui/material/Link'

function GroupApp(props) {
    const matches = useMediaQuery(`(max-width:1600px)`)
    const { dataDrawer, openDrawer, handleClose } = props
    return (

        <FormBox
            maxWidth={'682px'}
            key={dataDrawer.id}
            alignItems="center"
            title={dataDrawer.name}
            open={openDrawer}
            onClose={handleClose}
            content={
                <StyledForm>
                    <Grid container spacing={3}>
                        {dataDrawer?.child?.map(item => {
                            if (item.type?.toString() !== TYPE_APP.MOBILE && item.status)
                                return <Grid key={item.id} item md={6}>
                                    <Link className='item' href={item?.url}>
                                        <Box className='left'>
                                            <img src={item?.logo || '/'} alt="" />
                                        </Box>
                                        <Box className='right'>
                                            <Typography className='name'>{item.name}</Typography>
                                            <Typography className='description'>{item.description?.length > 50 ? item.description.slice(0, 50) + '...' : item.description}</Typography>
                                        </Box>
                                    </Link>
                                </Grid>
                        })}
                    </Grid>
                </StyledForm>
            } />

    )
}

const StyledForm = styled('div')(() => {
    return {
        padding: '8px 24px 12px 24px',
        '& .item': {
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            '& .right': {
                marginLeft: '12px',
                '& .name': {
                    fontWeight: '500',
                    color: '#333'
                },
                '& .description': {
                    fontSize: '12px',
                    fontWeight: '400',
                    lineHeight: '16px',
                    color: '#737373'
                }
            },
            '& .left': {
                '& img': {
                    width: '40px',
                    height: '40px'
                }
            }
        },

    }
})

export default GroupApp
