import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  timeOutSessionLogin: false,
}

const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    // Login
    login: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    loginSuccess: (state, action) => {
      return {
        loading: false,
      }
    },
    loginError: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    // Logout
    logout: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    logoutSuccess: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    logoutError: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
  },
})

export const { actions: authActions, reducer: authReducer } = authSlice
export const authSelector = (state) => state.auth
