import { useEffect } from "react"
import { detectMob } from "utils/validate"
import platform from "platform"
import { downloadFileIos } from "services/portal.service"
import { useDispatch } from "react-redux"
import { createMessage } from "utils/common"
import { MESSAGE_TYPES, URLs } from "constants/common"

const InstallApp = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        if(detectMob()){
            if (platform.os.family === 'iOS') {
                downloadFileIos()
                  .then((res) => {
                    window.location.href = res?.link
                  })
                  .catch((err) => {
                    dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: 'Không thể lấy link download!' }))
                  })
              } else if (platform.os.family === 'Android') {
                window.location.href = URLs.ANDROID_LINK_DOWNLOAD
              } else {
                window.location.href = URLs.ANDROID_LINK_DOWNLOAD
              }
        }
    })

    return (
        <></>
    )
}

export default InstallApp