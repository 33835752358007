/**
 * isMobilePhone
 * @param {String} str: Số điện thoại
 * @returns {Boolean} true/false
 */
export const isMobilePhone = (str) => {
  return /^(\+?84|0)?((1(2([0-9])|6([2-9])|88|86|99))|((7|8|9)((?!5)[0-9])))([0-9]{7})$/.test(str)
}

/**
 * isImage
 * @param {String} filePath
 * @returns {Boolean} true/false
 */
export const isImage = (filePath) => {
  if (!filePath) return false

  const extensions = new Set([
    '3dv',
    'ai',
    'amf',
    'art',
    'ase',
    'awg',
    'blp',
    'bmp',
    'bw',
    'cd5',
    'cdr',
    'cgm',
    'cit',
    'cmx',
    'cpt',
    'cr2',
    'cur',
    'cut',
    'dds',
    'dib',
    'djvu',
    'dxf',
    'e2d',
    'ecw',
    'egt',
    'emf',
    'eps',
    'exif',
    'fs',
    'gbr',
    'gif',
    'gpl',
    'grf',
    'hdp',
    'heic',
    'heif',
    'icns',
    'ico',
    'iff',
    'int',
    'inta',
    'jfif',
    'jng',
    'jp2',
    'jpeg',
    'jpg',
    'jps',
    'jxr',
    'lbm',
    'liff',
    'max',
    'miff',
    'mng',
    'msp',
    'nef',
    'nitf',
    'nrrd',
    'odg',
    'ota',
    'pam',
    'pbm',
    'pc1',
    'pc2',
    'pc3',
    'pcf',
    'pct',
    'pcx',
    'pdd',
    'pdn',
    'pgf',
    'pgm',
    'PI1',
    'PI2',
    'PI3',
    'pict',
    'png',
    'pnm',
    'pns',
    'ppm',
    'psb',
    'psd',
    'psp',
    'px',
    'pxm',
    'pxr',
    'qfx',
    'ras',
    'raw',
    'rgb',
    'rgba',
    'rle',
    'sct',
    'sgi',
    'sid',
    'stl',
    'sun',
    'svg',
    'sxd',
    'tga',
    'tif',
    'tiff',
    'v2d',
    'vnd',
    'vrml',
    'vtf',
    'wdp',
    'webp',
    'wmf',
    'x3d',
    'xar',
    'xbm',
    'xcf',
    'xpm',
  ])

  const fileExt = filePath.split('.').pop()

  return extensions.has(fileExt.toLowerCase())
}

export const isValidUrl = (urlString) => {
  let url;
  try {
    url = new URL(urlString);
  } catch (e) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};

export function detectMob() {
  const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
  ];
  
  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}