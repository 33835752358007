import UserRoleLevel from 'constants/user-role-level';
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

function checkPermission(listRole, role, module) {
  console.log(listRole, role, module);
  // Kiểm tra xem module có tồn tại trong listRole không
  if (listRole?.hasOwnProperty(module)) {
      // Kiểm tra xem role có tồn tại trong module không
      if (listRole[module].includes(role)) {
          return true; // Trả về true nếu thoả mãn
      }
  }
  return false; // Trả về false nếu không thoả mãn
}

function ProtechPath({ role, module }) {
  const userInfo = useSelector((state) => state.user.user)
  const userPermission = userInfo?.permission_modules
  console.log(checkPermission(userPermission, role, module));
  const isSuperAdmin = () => {
    return userInfo?.is_admin
  }

  if (checkPermission(userPermission, role, module) || isSuperAdmin()) {
    return <Outlet />
  } else return <Navigate to="/404" />
}

export default ProtechPath
