// ==============================|| THEME CONFIG  ||============================== //

const config = {
  defaultPath: '/',
  // fontFamily: `"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
  fontFamily: `"-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif"`,
  i18n: 'en',
  miniDrawer: false,
  container: true,
  // mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr',
}

export default config
export const drawerBgColor = 'rgb(35, 48, 68)'
export const mainBgColor = '#f7f7f7'
export const drawerWidth = 260
export const headerHeight = 65

export const footerToolbarHeight = 65

export const twitterColor = '#1DA1F2'
export const facebookColor = '#3b5998'
export const linkedInColor = '#0e76a8'

export const SHARE_COOKIE_DOMAIN = process.env.REACT_APP_SHARE_COOKIE_DOMAIN || '127.0.0.1'